import React, { useState } from "react";
import { Button, Box, Input } from "@chakra-ui/react";
import { formatOKS } from "./utils.js";

// Use synthetix transferableSynthetix(address account) to display how
// many OKS can be transferred

// TODO: better form validation and error messages
function Transfer({ addressInfo, transfer }) {
  const [balance, setBalance] = useState(
    formatOKS(addressInfo.transferableOKS)
  );
  const [destAddress, setDestAddress] = useState(addressInfo.address);

  function onChangeBalance(e) {
    setBalance(e.target.value);
  }
  function onChangeDestAddress(e) {
    setDestAddress(e.target.value);
  }

  return (
    <Box style={{ marginTop: "5%", textAlign: "left" }}>
      <Box>
        <strong>OKS Balance to transfer to BSC (min 10 OKS):</strong>
        <br />
        <br />
        <Input
          type="number"
          value={balance}
          onChange={onChangeBalance}
          style={{ color: "white", width: "410px" }}
        />
      </Box>
      <br />
      <Box>
        <strong>Recipient address on BSC:</strong>
        <br />
        <br />
        <Input
          type="text"
          placeholder="0x..."
          value={destAddress}
          onChange={onChangeDestAddress}
          style={{ color: "white", width: "410px" }}
        />
      </Box>
      <Box m={4}>
        <Button
          onClick={() => transfer(destAddress, balance)}
          colorScheme="blue"
          style={{ width: "410px" }}
        >
          Transfer OKS to Binance Smart Chain
        </Button>
      </Box>
    </Box>
  );
}

export default Transfer;
