import React, { useEffect, useState } from "react";
import {
  AlertDescription,
  AlertIcon,
  Alert,
  AlertTitle,
  Button,
  Box,
  Link,
  Spinner,
  Text,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import Approve from "./Approve";
import Transfer from "./Transfer";
import AddressInfo from "./AddressInfo";
import BalanceInfo from "./BalanceInfo";
import Footer from "./Footer";
import FAQ from "./FAQ";

import {
  web3,
  OKSPortalContract,
  OKSPortalAddress,
  OKSContract,
  getAddressInfo,
  MAX_UINT256,
} from "./utils.js";

function txUrl(txHash) {
  return `https://tronscan.org/#/transaction/${txHash.substr(2)}`;
}

function TransferSuccess({ txData }) {
  return (
    <Alert
      status="success"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
      my={4}
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle>Transfer succeeded</AlertTitle>
      <AlertDescription>
        <Text>
          It may take up to one hour before the OKS is credited to the recipient
          address on Binance Smart Chain (BSC).
        </Text>
        <Text>
          <Link textDecoration="underline" href={txUrl(txData.hash)} isExternal>
            View transaction on Tronscan
          </Link>{" "}
        </Text>
      </AlertDescription>
    </Alert>
  );
}

function OKSPortal() {
  const [error, setError] = useState(false);
  const [account, setAccount] = useState(false);
  const [addressInfo, setAddressInfo] = useState();
  const [pending, setPending] = useState(false);
  const [transferSuccess, setTransferSuccess] = useState(false);

  useEffect(() => {
    async function doAsync() {
      try {
        const accounts_ = await web3.eth.getAccounts();
        setAccount(accounts_[0]);
      } catch (err) {
        if (err.message === "tronWeb not detected") {
          setAccount(false);
          return;
        }
        throw err;
      }
    }
    doAsync().catch(setError);
  }, []);

  useEffect(() => {
    if (!account) return;
    async function doAsync() {
      setAddressInfo(await getAddressInfo(account));
    }
    doAsync().catch(setError);
  }, [account]);

  async function approve() {
    setPending(true);
    try {
      setPending(true);
      const res = await OKSContract.methods
        .approve(OKSPortalAddress, MAX_UINT256)
        .send({ from: account });
      console.log(res);
      setAddressInfo(await getAddressInfo(account));
      setPending(false);
    } catch (err) {
      console.log({ err });
      setError(err);
      setPending(false);
    }
  }

  async function transfer(destAddress, amountStr) {
    try {
      setPending(true);
      const amount = web3.utils.toWei(amountStr);
      const res = await OKSPortalContract.methods
        .sendOKSToBSC(destAddress, amount)
        .send({ from: account });
      setAddressInfo(await getAddressInfo(account));
      setTransferSuccess(res);
      setPending(false);
    } catch (err) {
      setPending(false);
      console.error(err);
      setError(err);
    }
  }

  if (error) {
    if (error.receipt) {
      return (
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
          height="200px"
          my={4}
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle>Transaction failed</AlertTitle>
          <AlertDescription>
            <Text>Maybe you ran out of TRX?</Text>
            <Text>
              <Link
                textDecoration="underline"
                href={txUrl(error.receipt.hash)}
                isExternal
              >
                View transaction on Tronscan
              </Link>{" "}
            </Text>
            <Button
              colorScheme="red"
              my={2}
              onClick={() => {
                window.location = "/";
              }}
            >
              Refresh the page to try again
            </Button>
          </AlertDescription>
        </Alert>
      );
    }
    return (
      <Alert
        status="error"
        variant="subtle"
        flexDirection="column"
        alignItems="left"
        justifyContent="left"
        textAlign="left"
        height="200px"
        my={4}
        style={{ padding: "20px", color: "red", width: "auto" }}
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle>{error.name}</AlertTitle>
        <AlertDescription>
          <Text>{error.message}</Text>
          {error.receipt && (
            <Text>
              <Link
                textDecoration="underline"
                href={txUrl(error.receipt.hash)}
                isExternal
              >
                View transaction on Tronscan
              </Link>{" "}
            </Text>
          )}
          <Button
            colorScheme="red"
            my={2}
            onClick={() => {
              window.location = "/";
            }}
          >
            Refresh the page to try again
          </Button>
        </AlertDescription>
      </Alert>
    );
  }
  if (pending) {
    return (
      <div style={{ textAlign: "left" }}>
        <Spinner /> Waiting for transaction...
      </div>
    );
  }

  if (!account) {
    return (
      <div style={{ textAlign: "left" }}>
        <p>Please unlock Tronlink and refresh the page.</p>
      </div>
    );
  }

  if (!addressInfo) {
    return (
      <Box style={{ textAlign: "left" }}>
        <Spinner /> Loading data...
      </Box>
    );
  }

  return (
    <Grid
      h="200px"
      templateRows="repeat(2, 1fr)"
      templateColumns="repeat(5, 1fr)"
      gap={4}
    >
      <GridItem
        colSpan={2}
        style={{ border: "1px solid white", padding: "20px", width: "410px" }}
      >
        <AddressInfo {...addressInfo} />

        <BalanceInfo {...addressInfo} />
      </GridItem>
      <GridItem colSpan={4} style={{ textAlign: "left" }}>
        {addressInfo.approved ? (
          <div>
            {transferSuccess ? (
              <TransferSuccess txData={transferSuccess} />
            ) : (
              <Transfer addressInfo={addressInfo} transfer={transfer} />
            )}
          </div>
        ) : (
          <Approve approve={approve} />
        )}
      </GridItem>
      <GridItem colSpan={4} style={{ textAlign: "left" }}>
        <Footer />
      </GridItem>
      <GridItem colSpan={4} style={{ textAlign: "left" }}>
        <FAQ />
      </GridItem>
    </Grid>
  );
}

export default OKSPortal;
