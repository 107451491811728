import logo from "./logo.svg";
import "./App.css";
import OKSPortal from "./OKSPortal/index.js";

import {
  ThemeProvider,
  CSSReset,
  Box,
  Center,
  Heading,
  Container,
  Link,
  Grid, GridItem,
} from "@chakra-ui/react";
import customTheme from "./theme";

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <div class="App">
      <Grid
        h="200px"
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(5, 1fr)"
        gap={4}>
 
        <GridItem colSpan={3}  >
            <Heading size="lg" my={2} >
              <Link href="/">
              <img
                src={logo}
                alt="Oikos"
                style={{ height: "50px", marginTop:"20px", marginLeft: "15px", position:"absolute", left:"0", marginLeft:"30px" }}
              />
            </Link>             
            <br />
              <p style={{marginTop:'50px', marginLeft: "20px", position:"relative", width:"100%", textAlign:"left"}}>
                Tron → BSC Migration Wizard
              </p>    
            </Heading>
               
            <Container  maxW="container.lg" >
              <br />
              <OKSPortal />
            </Container>       
        </GridItem>
      </Grid>
      </div>
    </ThemeProvider>
  );
}

export default App;

 