import React from "react";
import { formatOKS, formatAddress } from "./utils.js";

import { Box, Code } from "@chakra-ui/react";

// Apprvoe OKSPortal contract to transfer OKS on behalf of user
// TODO: proprely format OKS
function BalanceInfo({ OKSBalance, transferableOKS, collateral }) {
  return (
    <Box>
      <Box display={{ md: "flex" }} style={{ textAlign: "left" }}>
        <Box flexShrink={0}>
          <strong>Total:</strong>{" "}
          <Code style={{ color: "green", fontSize: "16px" }}>
            {formatOKS(collateral)}
          </Code>{" "}
          OKS
        </Box>
      </Box>
      <Box display={{ md: "flex" }} style={{ textAlign: "left" }}>
        <Box flexShrink={0}>
          <strong>Transferable:</strong>{" "}
          <Code style={{ color: "green", fontSize: "16px" }}>
            {formatOKS(transferableOKS)}
          </Code>{" "}
          OKS
        </Box>
      </Box>
    </Box>
  );
}

export default BalanceInfo;
