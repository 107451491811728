import React from "react";

import { Link, Text, Heading } from "@chakra-ui/react";

const Question = ({ children }) => <Heading size="md">{children}</Heading>;
const Answer = ({ children }) => <Text>{children}</Text>;

const SwapLink = () => (
  <Link href="https://swap.oikos.cash" isExternal textDecor="underline">
    swap
  </Link>
);
const MinterLink = () => (
  <Link href="https://minter-tron.oikos.cash" isExternal textDecor="underline">
    minter
  </Link>
);

function FAQ() {
  return (
    <footer>
      <Question>How to transfer locked OKS?</Question>
      <Answer>
        <span style={{ fontWeight: "bold" }}>Staked OKS: </span>
        Burn your synths on <MinterLink /> and transfer the OKS to BSC here. If
        you don't have enough synths, you can buy sUSD on <SwapLink />.
      </Answer>
      <Answer>
        <span style={{ fontWeight: "bold" }}>Escrowed OKS: </span>
        Go to <MinterLink />, click the "Vest all" button and transfer the OKS
        to BSC here.
      </Answer>
      <Question>How to transfer leftover synths?</Question>
      <Answer>
        Convert your synths to sUSD using <MinterLink />, exchange them for OKS
        using <SwapLink /> and transfer the OKS to BSC here.
      </Answer>
    </footer>
  );
}
export default FAQ;
