// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react"
import { createBreakpoints } from "@chakra-ui/theme-tools"
// 2. Update the breakpoints as key-value pairs
const breakpoints = createBreakpoints({
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
})

// 2. Call `extendTheme` and pass your custom values
const customTheme = extendTheme({
  colors: {
    brand: {
      100: "white",
      900: "#1a202c",
    },
  },
  breakpoints
})

export default customTheme;
