import React from "react";
import { formatOKS, formatAddress } from "./utils.js";

import { Box, Code } from "@chakra-ui/react";

// Apprvoe OKSPortal contract to transfer OKS on behalf of user
// TODO: proprely format OKS
function AddressInfo({ address, OKSBalance }) {
  return (
    <Box display={{ md: "flex" }}  style={{textAlign:"left"}} >
      <Box  flexShrink={0} >   
 
        <strong>Address:</strong> <Code style={{color:"green", fontSize:"16px"}}>{formatAddress(address)}</Code>

      </Box>
    </Box>
  );
}

export default AddressInfo;
