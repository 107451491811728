import React from "react";

import { Link } from "@chakra-ui/react";

function Footer() {
  return (
    <footer>
      <div>
        <Link
          href="https://oikoscash.medium.com/oikos-migrates-to-bsc-mainnet-d3f8f1271ea7"
          isExternal
        >
          What is this?
        </Link>{" "}
      </div>
      <div>
        <Link
          href="https://bscscan.com/token/0x18aCf236eB40c0d4824Fb8f2582EBbEcD325Ef6a"
          isExternal
        >
          OKS on Binance Smart Chain
        </Link>
      </div>
      <div>
        <Link href="https://minter.oikos.cash" isExternal>
          Minter on Binance Smart Chain
        </Link>
      </div>
      <div>
        <Link href="https://oikos.exchange" isExternal>
          Exchange on Binance Smart Chain
        </Link>
      </div>
      <div>
        <Link href="https://minter-tron.oikos.cash" isExternal>
          Minter on Tron (obsolete)
        </Link>
      </div>
    </footer>
  );
}
export default Footer;
