import React from "react";

import { Button, Box } from "@chakra-ui/react";

// Apprvoe OKSPortal contract to transfer OKS on behalf of user
function Approve({ approve }) {
  return (
    <Box p={4}>
      <Button onClick={approve}>Approve contract to transfer OKS</Button>
    </Box>
  );
}

export default Approve;
