import Web3 from "web3";
import createTronLinkProvider from "@opentron/tronlink-provider";
import { ethAddress } from "@opentron/tron-eth-conversions";
import promiseRetry from "promise-retry";

import ERC20Abi from "../abis/erc20.js";
import portalAbi from "../abis/portal.js";
import synthetixAbi from "../abis/synthetix.js";

import { OKSAddress, OKSPortalAddress, synthetixAddress } from "./constants.js";

export { OKSPortalAddress };

const allAbis = [...ERC20Abi, ...portalAbi];

export const provider = createTronLinkProvider({
  network: "mainnet",
  functionSignatures: allAbis,
});

export const web3 = new Web3(provider);

export const OKSPortalContract = new web3.eth.Contract(
  portalAbi,
  OKSPortalAddress
);

export const SynthetixContract = new web3.eth.Contract(
  synthetixAbi,
  synthetixAddress
);

export const OKSContract = new web3.eth.Contract(ERC20Abi, OKSAddress);

export function formatOKS(amount) {
  return web3.utils.fromWei(amount);
}

// retry timeout errors from tron api
function autoRetryCall(promiseFn) {
  return promiseRetry((retry, _number) => {
    // only retry on timeout errors
    return promiseFn().catch((err) => {
      if (err.message && err.message.match(/timeout/)) {
        console.log({ err });
        return retry(err);
      }
      throw err;
    });
  });
}

const BN = web3.utils.BN;

// return info about address (locked OKS, total OKS, etc.)
export async function getAddressInfo(address) {
  const snx = SynthetixContract;
  let [allowance, OKSBalance, transferableOKS, collateral] = await Promise.all(
    [
      () => snx.methods.allowance(address, OKSPortalAddress).call(),
      () => snx.methods.balanceOf(address).call(),
      () => snx.methods.transferableSynthetix(address).call(),
      () => snx.methods.collateral(address).call(),
    ].map(autoRetryCall)
  );

  console.log({ allowance, OKSBalance, transferableOKS });
  // Hack!
  // Theres bug where when transferableSynthetix() reverts, it returns a
  // huge number instead...
  if (new BN(transferableOKS).gte(new BN("1000000000000000000000000000"))) {
    console.warn("transferableSynthetix(address) reverted!");
    transferableOKS = "0";
  }
  console.log({ allowance, OKSBalance, transferableOKS });
  const approved = allowance !== "0";

  return { address, approved, OKSBalance, transferableOKS, collateral };
}

export const formatAddress = ethAddress.toTron;
export const MAX_UINT256 = web3.utils.toBN(
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
);
